@import "../../variables";
@import "../../_media-queries";
@import "../../_shared-styles";

.project-acceptance-section {
    margin-top: 64px;
    padding: 24px 0;

    @include only-phone-xl {
        margin-top: 40px;
    }

    .project-acceptance-header-wrap {
        .project-acceptance-header {
            .project-acceptance-title {
                @extend .main-title;
                text-align: center;
            }
        }
    }

    .project-acceptance-content {
        margin-top: 24px;

        .project-acceptance-slider-wrapper {
            .project-acceptance-item{
                height: 100%;
            }

            .pa-card{
                height: 100%;
            }

            .slider-footer {
                margin-top: 42px;
                display: flex;
                align-items: center;
                justify-content: center;

                .slider-btns {
                    display: flex;
                    align-items: center;

                    .slider-btn-next,
                    .slider-btn-prev {
                        --btn-color: var(--primary-color);
                        --btn-color-hover: var(--primary-color);
                    }

                    .slider-dots {
                        margin: 0 12px;
                    }

                }
            }
        }
    }
}