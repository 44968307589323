@import "../variables";

.#{$prefix}-btn-base {
    --btn-color: var(--secondary-color);
    --btn-color-hover: var(--secondary-color-action);
    padding: 12px 20px;
    border-radius: 12px;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
    vertical-align: middle;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    min-height: 48px;
    position: relative;
}

.#{$prefix}-btn-primary {
    @extend .#{$prefix}-btn-base;
    background-color: var(--btn-color);
    color: #fff;

    .btn-content {
        display: inherit;

        &.btn-loading {
            visibility: hidden;
        }
    }

    .btn-loader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        background-color: var(--btn-color-hover);
    }

    i {
        font-size: 20px;
    }

    &.btn-outline {
        background-color: transparent;
        color: var(--btn-color);
        border: 1px solid var(--btn-color);

        &:hover {
            background-color: var(--btn-color);
            color: #fff;
        }
    }

    &.btn-neutral {
        background-color: #fff;
        color: #475467;
        border-color: #EAECF0;

        &:hover {
            background-color: #F0F0F0;
        }
    }

    &.btn-text {
        background-color: transparent;
        color: #475467;
        border-color: transparent;

        &:hover {
            background-color: #F0F0F0;
        }
    }

    &.btn-icon-only {
        width: 48px;
        height: 48px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 20px;
        }
    }
}

.#{$prefix}-btn-with-icon {
    @extend .#{$prefix}-btn-base;
    padding-left: 60px;
    display: inline-flex;
    align-items: center;
    background-color: var(--btn-color);
    color: #fff;
    position: relative;

    &:hover {
        background-color: var(--btn-color-hover);
    }

    .btn-icon {
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;
        position: absolute;
        left: 4px;
        top: 50%;
        transform: translateY(-50%);

        i {
            font-size: 24px;
            color: var(--btn-color);
        }

        .btn-icon-content {
            display: inherit;
    
            &.btn-loading {
                display: none;
            }
        }
    
        .btn-loader {
            display: flex;
            align-items: center;
            justify-content: center;
        }    
    }

    .btn-content {
        min-width: 140px;
        text-align: center;
        flex: 1;
    }
}