@import "../../variables";

.#{$prefix}-decoration-3 {
    svg{
        overflow: visible;
    }
    
    path:nth-child(2),
    path:nth-child(3),
    path:nth-child(4) {
        transition: transform 0.1s ease;
    }
}