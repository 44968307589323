@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes vibrate {
    0% {
        transform: translate(0);
    }

    20% {
        transform: translate(-2px, 2px);
    }

    40% {
        transform: translate(-2px, -2px);
    }

    60% {
        transform: translate(2px, 2px);
    }

    80% {
        transform: translate(2px, -2px);
    }

    100% {
        transform: translate(0);
    }
}

@keyframes vibrate-mild{
    0% {
        transform: translate(0);
    }

    20% {
        transform: translate(-1px, 1px);
    }

    40% {
        transform: translate(-1px, -1px);
    }

    60% {
        transform: translate(1px, 1px);
    }

    80% {
        transform: translate(1px, -1px);
    }

    100% {
        transform: translate(0);
    }
}

@keyframes vibrate-with-rotation {
    0% {
        transform: translate(0) rotate(0);
    }

    20% {
        transform: translate(-2px, 2px) rotate(2deg);
    }

    40% {
        transform: translate(-2px, -2px) rotate(-2deg);
    }

    60% {
        transform: translate(2px, 2px) rotate(2deg);
    }

    80% {
        transform: translate(2px, -2px) rotate(-2deg);
    }

    100% {
        transform: translate(0) rotate(0);
    }
}

@keyframes vibrate-with-zoom {
    0% {
        transform: translate(0) scale(1);
    }

    20% {
        transform: translate(-2px, 2px) scale(0.91);
    }

    40% {
        transform: translate(-2px, -2px) scale(1.09);
    }

    60% {
        transform: translate(2px, 2px) scale(0.91);
    }

    80% {
        transform: translate(2px, -2px) scale(1.09);
    }

    100% {
        transform: translate(0) scale(1);
    }
}