@import "../variables";

.#{$prefix}-project-acceptance-card-wrap {
    padding-top: 60px;

    .project-acceptance-card {
        border-radius: 16px;
        border: 1px solid #EAECF0;
        background: #FFF;
        // box-shadow: 0px 42px 50px 0px rgba(0, 0, 0, 0.03);
        height: 100%;

        .project-acceptance-card-icon-wrap {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background: #F2F4F7;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            margin-top: -60px;
            transition: all 0.2s ease-in-out;

            i {
                font-size: 44px;
            }
        }

        .project-acceptance-card-content {
            padding: 20px;

            .project-acceptance-card-title {
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                text-align: center;
            }

            .project-acceptance-card-description {
                color: #667085;
                text-align: center;
                font-family: Yekan Bakh;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-top: 20px;
            }
        }

        &:hover {
            .project-acceptance-card-icon-wrap {
                background: var(--primary-color);
                color: #FFF;
            }
        }
    }
}