@import "../../variables";
@import "../../media-queries";

.portfolio-hero-title {
    padding-top: 48px;

    .portfolio-title {
        color: #242A32;
        font-size: 26px;
        font-weight: 300;

        @include only-phone-xl {
            font-size: 24px;
        }

        @include only-phone-lg {
            font-size: 22px;
        }

        @include only-phone-md {
            font-size: 20px;
        }
    }
}

.portfolio-grid {
    padding: 40px 0;
}