.project-gallery-slider-wrapper {
    .project-gallery-slider-main {
        border-radius: 12px;
        overflow: hidden;

        .splide-slide {
            border-radius: 12px;
            overflow: hidden;
        }
    }

    .project-gallery-slider-thumbs {
        margin-top: 15px;
        border-radius: 12px;
        overflow: hidden;

        .splide-slide {
            border-radius: 12px;
            overflow: hidden;
        }
    }
}