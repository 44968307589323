@import "../../variables";
@import "../../media-queries";

.portfolio-grid{
    .portfolio-grid-content{
        .grid-row{
            display: flex;
            flex-wrap: wrap;
            margin: -15px;

            .grid-col{
                padding: 15px;
                width: 50%;

                @include only-phone-lg {
                    width: 100%;
                }
            }
        }
    }
}