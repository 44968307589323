@import "../../variables";
@import "./animations";

.#{$prefix}-decoration-2 {
    --decoration-color-1: #E1E8EB;
    --decoration-color-2: var(--primary-color, #0EA5E9);
    --decoration-color-3: var(--secondary-color, #FF7C04);

    svg{
        overflow: visible;
    }

    path:nth-child(1) {
        animation: vibrate 12s linear infinite;
        transition: stroke 3s ease-in-out;
        stroke: var(--decoration-color-1);
    }

    path:nth-child(2) {
        animation: vibrate 10s linear infinite;
        transition: stroke 3s ease-in-out;
    }

    path:nth-child(3) {
        animation: vibrate 15s linear infinite;
        transition: stroke 3s ease-in-out;
    }

    path:nth-child(4) {
        transform-origin: center;
        animation: vibrate-with-rotation 8s linear infinite;
    }
}