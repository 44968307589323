@import "../../../variables";
@import "../../../media-queries";

.modal-content-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 952px;
    padding: 0 16px;
    outline: none;

    .modal-content {
        width: 100%;
        max-width: 920px;
        background-color: #FFF;
        box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
        border-radius: 20px;
        padding: 24px;
        max-height: 90vh;
        overflow-y: auto;

        .modal-head {
            padding-bottom: 24px;
            border-bottom: 1px solid rgba(229, 229, 229, 0.60);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .modal-title {
                color: #101828;
                font-size: 24px;
                font-weight: 800;

                @include only-phone-md{
                    font-size: 20px;
                }
            }

            .modal-close {
                cursor: pointer;
                color: #101828;
                font-size: 24px;
                font-weight: 800;
                display: flex;
                background: none;

                @include only-phone-md{
                    font-size: 20px;
                }
            }
        }

        .modal-body {
            padding-top: 24px;
        }
    }
}