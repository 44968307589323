@import "../variables";

.#{$prefix}-input-base {
    border-radius: 8px;
    border: 1px solid #EAECF0;
    background: #F9FAFB;
    padding: 16px 15px;
    transition: all 0.3s ease-in-out;
    vertical-align: middle;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    min-height: 48px;
    width: 100%;
    color: #101828;
    outline: none;
    box-shadow: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;

    &::placeholder {
        color: #666666;
    }

    &:focus {
        border: 1px solid var(--secondary-color);
    }

    &:disabled {
        background-color: #F5F6FA;
        border: 1px solid #EAECF0;
        color: #B3B5C2;
        cursor: not-allowed;
    }
}

.#{$prefix}-input-placeholder-base {
    color: #666666;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}


.#{$prefix}-input-wrapper {
    position: relative;

    .#{$prefix}-input-primary {
        .#{$prefix}-input-placeholder {
            @extend .#{$prefix}-input-placeholder-base;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            display: none;

            &.show {
                display: block;
            }

            .#{$prefix}-input-required {
                color: var(--secondary-color);
                margin-right: 5px;
            }
        }

        .#{$prefix}-input {
            @extend .#{$prefix}-input-base;

            &.with-icon {
                padding-left: 40px;
            }
        }

        .#{$prefix}-input-icon {
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            color: #101828;
            font-size: 18px;
            transition: all 0.3s ease-in-out;
            pointer-events: none;
        }
    }

    .#{$prefix}-input-textarea {
        .#{$prefix}-input-placeholder {
            @extend .#{$prefix}-input-placeholder-base;
            position: absolute;
            top: 18px;
            right: 15px;
            display: none;

            &.show {
                display: block;
            }

            .#{$prefix}-input-required {
                color: var(--secondary-color);
                margin-right: 5px;
            }
        }

        .#{$prefix}-input {
            @extend .#{$prefix}-input-base;

            &.with-icon {
                padding-left: 40px;
            }
        }

        .#{$prefix}-input-icon {
            position: absolute;
            top: 15px;
            left: 15px;
            color: #101828;
            font-size: 18px;
            transition: all 0.3s ease-in-out;
            pointer-events: none;
        }
    }
}