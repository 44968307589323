@import "../variables";
@import "../media-queries";

.#{$prefix}-accordion {
    border-radius: 16px;
    border: 1px solid #EAECF0;
    background: #FFF;
    overflow: hidden;

    .accordion-items {
        .accordion-item {
            transition: padding-bottom 0.3s ease;
            border-bottom: 1px solid #EAECF0;

            &.show {
                padding-bottom: 15px;

                .accordion-btn {
                    margin-bottom: 10px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
            }

            &:last-child {
                border-bottom: none;
            }

            .accordion-btn {
                background-color: #ffffff;
                padding: 20px;
                padding-left: 56px;
                cursor: pointer;
                transition: background-color 0.3s ease, margin-bottom 0.3s ease, padding 0.3s ease;
                font-size: 16px;
                font-weight: 700;
                line-height: 140%;
                color: #101828;
                position: relative;

                @include only-phone-md{
                    font-size: 14px;
                }

                &:hover {
                    background-color: #F2F4F7;
                }

                .accordion-icon{
                    position: absolute;
                    left: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: transform 0.3s ease;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &.show {
                .accordion-btn {
                    .accordion-icon {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }

            .accordion-content {
                overflow: hidden;
                max-height: 0;
                padding: 0 20px;
                transition: max-height 0.3s ease;
                box-sizing: border-box;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                color: #667085;
            }
        }

    }
}