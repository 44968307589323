@import "../../variables";
@import "../../_media-queries";
@import "../../_shared-styles";

.gallery-section {
    margin-top: 64px;
    padding: 24px 0;

    @include only-phone-xl {
        margin-top: 40px;
    }

    .gallery-section-header-wrap {
        .gallery-section-header {
            .gallery-section-title {
                @extend .main-title;
                text-align: center;
                line-height: normal;
            }
        }
    }

    .gallery-section-content {
        margin-top: 32px;

        .gallery-slider-wrapper{
            .gallery-slider-main{
                border-radius: 12px;
                overflow: hidden;

                .splide-slide{
                    border-radius: 12px;
                    overflow: hidden;
                }
            }

            .gallery-slider-thumbs{
                margin-top: 15px;
                border-radius: 12px;
                overflow: hidden;

                .splide-slide{
                    border-radius: 12px;
                    overflow: hidden;
                }
            }

        }
    }
}