@import "../variables";
@import "../media-queries";

.#{$prefix}-project-card {
    border-radius: 20px;
    border: 1px solid #EAECF0;
    background: #FFF;
    padding: 22px;
    transition: all 0.2s ease-in-out;

    @include only-phone-md{
        padding: 20px;
    }

    &:hover{
        .project-card-pic{
            &::after{
                opacity: 1;
            }
        }
    }

    .project-card-pic {
        height: 310px;
        background-color: #E0E2E6;
        border-radius: 16px;
        overflow: hidden;
        position: relative;

        @include only-phone-md {
            height: 160px;
        }

        //hover effect
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 310px;
            background: linear-gradient(180deg, rgba(14, 165, 233, 0.00) 0%, rgba(14, 165, 233, 0.70) 100%); 
            pointer-events: none;
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 16px;
        }

    }

    .project-card-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        color: #101828;
        margin-top: 22px;

        @include only-phone-md {
            margin-top: 20px;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
        }
    }

    .project-card-properties {
        margin-top: 17px; //should -5 because of the gutter
        margin-bottom: -5px;
        
        @include only-phone-md {
            margin-top: 15px; //should -5 because of the gutter
        }

        .project-card-property {
            display: flex;
            align-items: center;
            padding-top: 5px;
            padding-bottom: 5px;

            @include only-phone-md {
                justify-content: space-between;
            }


            .project-card-property-title {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                color: #101828;
                margin-left: 10px;

                .project-card-property-icon {
                    font-size: 24px;
                    margin-left: 10px;
                    color: #101828;
                    vertical-align: middle;
                }
            }

            .project-card-property-value {
                color: #667085;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: #101828;
            }
        }
    }

    .project-card-btns {
        margin-top: 30px;
        display: flex;
        align-items: center;

        @include only-phone-md {
            flex-direction: column;
            margin-top: 20px;
        }

        .project-card-btn {
            width: 100%;
            margin-right: 22px;

            &:first-child {
                margin-right: 0;
            }

            @include only-phone-md {
                margin-right: 0;
                margin-top: 16px;

                &:first-child {
                    margin-top: 0;
                }
            }

        }
    }
}