@import "../../variables";
@import "../../media-queries";

.decoration-base{
    z-index: -1;
    pointer-events: none;
}

.home-dec-1 {
    @extend .decoration-base;
    position: relative;

    .dec{
        position: absolute;
        top: 0;
        right: -70px;

        @include only-phone-xl{
            right: -50px;
        }

        svg {
            // aspect-ratio: 156 / 142;
            @include only-phone-xl{
                width: 115px;
                height: 105px;
            }
        }
    }
}

.home-dec-2 {
    @extend .decoration-base;
    position: relative;

    .dec{
        position: absolute;
        top: 0;
        left: -60px;

        @include only-phone-xl{
            left: -40px;
        }

        svg {
            // aspect-ratio: 166 / 167;
            @include only-phone-xl{
                width: 115px;
                height: 115px;
            }
        }
    }
}

.home-dec-3 {
    @extend .decoration-base;
    position: relative;

    .dec{
        position: absolute;
        top: -70px;
        right: -60px;

        @include only-phone-xl{
            top: -55px;
            right: -40px;
        }

        @include only-phone-md{
            top: 0;
        }

        svg {
            // aspect-ratio: 202 / 210;
            width: 170px;
            height: 177px;

            @include only-phone-xl{
                width: 120px;
                height: 125px;
            }
        }
    }
}

.home-dec-4 {
    @extend .decoration-base;
    position: relative;

    .dec{
        position: absolute;
        top: -75px;
        left: -60px;

        @include only-phone-xl{
            top: -25px;
            left: -50px;
        }

        svg {
            // aspect-ratio: 164 / 205;
            @include only-phone-xl{
                width: 110px;
                height: 137px;
            }
        }
    }
}

.home-dec-5 {
    @extend .decoration-base;
    position: relative;

    .dec{
        position: absolute;
        top: 0;
        right: -60px;

        @include only-phone-xl{
            right: -45px;
        }

        @include only-phone{
            top: -10px;
            right: -40px;
        }

        svg {
            // aspect-ratio: 155 / 168;
            @include only-phone-xl{
                width: 110px;
                height: 120px;
            }

            @include only-phone-md{
                width: 90px;
                height: 97px;
            }
        }
    }
}