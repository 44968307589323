@import "../../variables";
@import "../../_media-queries";
@import "../../_shared-styles";

.faq-section {
    margin-top: 64px;
    padding: 24px 0;

    @include only-phone-xl {
        margin-top: 40px;
    }

    .faq-content-wrap {
        display: flex;
        align-items: center;
        
        @include only-phone-xl{
            flex-wrap: wrap;
        }

        .faq-title-mobile{
            @extend .main-title;
            width: 100%;
            text-align: center;
            display: none;

            @include only-phone-xl{
                display: block;
            }
        }

        .faq-content {
            max-width: 700px;
            width: 100%;
            margin-left: 64px;
            
            @include only-phone-xl{
                width: 100%;
                flex: 0 0 auto;
                margin: 0 auto;
                order: 2;
            }

            .faq-title {
                font-size: 30px;
                font-weight: 300;
                line-height: 60px;

                @include only-phone-xl{
                    display: none;
                }
            }

            .faq-accordion{
                margin-top: 44px;
            }
        }

        .faq-pic {
            max-width: 384px;
            margin-right: auto;
            margin-left: 64px;
            flex: 0 0 auto;

            @include only-phone-xxl{
                margin-left: 32px;
            }

            @include only-phone-xl{
                width: 100%;
                margin: 0 auto;
                margin-top: 24px;
                order:1;
            }

            @include only-phone{
                padding: 0 25px;
            }

            img{
                width: 100%;
                height: auto;
            }
        }
    }
}