@import "../../variables";
@import "./animations";

.#{$prefix}-decoration-1 {
    svg{
        overflow: visible;
        animation: rotate 20s linear infinite;
    }

    path:nth-child(1) {
        animation: vibrate-mild 12s linear infinite;
    }

    path:nth-child(2) {
        animation: vibrate-mild 10s linear infinite;
    }
}