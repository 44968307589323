@import "../variables";
@import "../media-queries";

.#{$prefix}-our-benefits-card-wrap {
    padding-top: 15px;

    @include only-phone{
        padding-top: 28px;
    }

    .our-benefits-card{
        border-radius: 16px;
        border: 1px solid #EAECF0;
        background: #FFF;
        //box-shadow: 0px 42px 50px 0px rgba(0, 0, 0, 0.03);
        padding: 22px;
        transition: all 0.2s ease-in-out;
        height: 100%;

        &:hover {
            background: var(--primary-color);
    
            .our-benefits-card-header {
                .our-benefits-card-title {
                    color: #FFF;
                }
            }
    
            .our-benefits-card-content {
                .our-benefits-card-description {
                    color: #FFF;
                }
            }
        }
    }

    .our-benefits-card-header {
        display: flex;
        align-items: center;
        margin-top: -37px;

        @include only-phone{
            flex-direction: column;
            margin-top: -50px;
        }

        .our-benefits-card-icon-wrap {
            width: 80px;
            height: 80px;
            border-radius: 12px;
            background: #F2F4F7;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: 44px;
            }
        }

        .our-benefits-card-title {
            transition: all 0.2s ease-in-out;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5;
            margin-right: 23px;
            margin-top: 15px;

            @include only-phone{
                margin-right: 0;
            }
        }
    }


    .our-benefits-card-content {
        .our-benefits-card-description {
            transition: all 0.2s ease-in-out;
            color: #667085;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin-top: 20px;
        }
    }
}