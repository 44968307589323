@import "../../variables";
@import "../../_media-queries";
@import "../../_shared-styles";

.our-benefits-section {
    margin-top: 64px;
    padding: 24px 0;

    @include only-phone-xl {
        margin-top: 40px;
    }

    .our-benefits-header-wrap {
        .our-benefits-header {
            .our-benefits-title {
                @extend .main-title;
                text-align: center;
            }
        }
    }

    .our-benefits-content {
        margin-top: 24px;

        .our-benefits-slider-wrapper {
            .our-benefits-item{
                height: 100%;
            }

            .ob-card{
                height: 100%;
            }

            .slider-footer {
                margin-top: 26px;
                display: flex;
                align-items: center;
                justify-content: center;

                .slider-btns {
                    display: flex;
                    align-items: center;

                    .slider-btn-next,
                    .slider-btn-prev {
                        --btn-color: var(--primary-color);
                        --btn-color-hover: var(--primary-color);
                    }

                    .slider-dots {
                        margin: 0 12px;
                    }

                }
            }
        }
    }
}