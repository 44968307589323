@import "../../variables";
@import "../../_media-queries";

.hero-section {
    padding: 24px 0;

    .hero-content-wrap {
        display: flex;

        @include only-phone-xl {
            flex-wrap: wrap;
        }

        .hero-content {
            padding-top: 48px;
            max-width: 668px;
            flex: 0 1 668px;
            margin-left: 48px;

            @include only-phone-xl {
                max-width: none;
                width: 100%;
                margin-left: 0;
                flex: 0 0 auto;
                padding-top: 0;
            }

            .hero-slogan {
                color: #D0D5DD;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                margin-bottom: 12px;

                @include only-phone-xl {
                    text-align: center;
                }
            }

            .hero-title {
                font-size: 36px;
                font-style: normal;
                font-weight: 800;
                line-height: 150%;

                @include only-phone {
                    font-size: 26px;
                }

                @include only-phone-xl {
                    text-align: center;
                }
            }

            .hero-desc {
                max-width: 445px;
                margin-top: 24px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: #667085;

                @include only-phone-xl {
                    max-width: 500px;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                }

                @include only-phone {
                    margin-top: 20px;
                    text-align: justify;
                }

            }

            .hero-quick-contact {
                margin-top: 80px;

                @include only-phone-xl {
                    display: none;
                }
            }
        }

        .hero-img {
            width: 445px;
            height: 530px;
            flex: 0 0 auto;
            border-radius: 30px;
            background: var(--primary-color);
            margin-right: auto;
            position: relative;

            @include only-phone-xl {
                margin-top: 60px;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                height: 255px;
            }

            .img-1 {
                width: 523px;
                height: 500px;
                position: absolute;
                bottom: 0;
                left: 50px;

                @include only-phone-xl {
                    width: 300px;
                    height: 282px;
                    left: 80px;
                }

                @include only-phone-lg {
                    left: 20px;
                }

                @include only-phone-md {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            .img-2 {
                display: none;
                width: 253px;
                height: 285px;
                position: absolute;
                bottom: 0;
                right: 80px;

                @include only-phone-xl {
                    display: block;
                }

                @include only-phone-lg {
                    right: 20px;
                }

                @include only-phone-md {
                    display: none;
                }
            }
        }

        .mobile-quick-contact{
            width: 100%;
            display: none;

            @include only-phone-xl {
                display: block;
                margin-top: 32px;
            }
        }
    }
}