@import "../../variables";
@import "../../_media-queries";
@import "../../_shared-styles";

.our-services-section {
    margin-top: 64px;
    padding: 24px 0;

    @include only-phone-xl{
        margin-top: 40px;
    }

    .our-services-content-wrap {
        .our-services-title {
            @extend .main-title;
            text-align: center;
        }

        .our-services-content-desktop {
            margin-top: 48px;

            @include only-phone-xl{
                display: none;
            }

            .our-services-items {
                --bs-gutter-x: 30px;
                --bs-gutter-y: 30px;

            }
        }
    }

    .our-services-content-mobile {
        margin-top: 24px;
        display: none;

        @include only-phone-xl{
            display: block;
        }

        .our-services-item {
            height: 100%;
        }
    }
}