@import "./media-queries";

.main-title{
    font-size: 30px;
    font-weight: 300;
    line-height: 60px;

    @include only-phone-md{
        font-size: 24px;
    }

    @include only-phone-xs{
        font-size: 20px;
    }
}