@import "../../variables";
@import "../../media-queries";

.#{$prefix}-req-counseling-form-wrapper {
    .req-counseling-title {
        color: #667085;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
    }

    .req-counseling-form{
        margin-top: 24px;

        .row{
            --bs-gutter-x: 20px;
            --bs-gutter-y: 20px;
        }

        .form-input{
            .rx-input-primary{
                .rx-input {
                    padding-top: 14px;
                    padding-bottom: 14px;
                    line-height: 12px;
                    font-size: 12px;
                }
            }
        }
        
        .form-btn{
            display: flex;
            margin-top: 4px;
            
            .submit-btn{
                margin-right: auto;

                @include only-phone-md {
                    width: 100%;
                }
            }
        }
    }
}