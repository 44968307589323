@import "../../variables";
@import "../../_media-queries";
@import "../../_shared-styles";

.hero-section-1 {
    padding: 80px 0;

    @include only-phone-xl {
        padding: 32px 0;
    }

    .hero-content-wrap {
        display: flex;
        align-items: center;

        @include only-phone-xl {
            flex-wrap: wrap;
        }

        .hero-title-mobile {
            width: 100%;
            display: none;
            margin-bottom: 40px;

            @include only-phone-xl {
                display: block;
            }

            .hero-top-title {
                text-align: center;
                color: #101828;
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 20px;
            }

            .hero-title {
                text-align: center;
                color: #101828;
                font-size: 26px;
                font-weight: 800;
                line-height: 46px;
            }
        }

        .hero-content {
            max-width: 600px;
            margin-left: 30px;

            @include only-phone-xl {
                width: 100%;
                flex: 0 0 auto;
                margin: 0 auto;
                order: 2;
                margin-top: 40px;
            }

            .hero-title-desktop {
                margin-bottom: 43px;

                @include only-phone-xl {
                    display: none;
                }
                
                .hero-top-title {
                    color: #101828;
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 20px;
                }
                
                .hero-title {
                    color: #101828;
                    font-size: 40px;
                    font-weight: 800;
                    line-height: 60px;
                }
            }

            .hero-text {
                color: #667085;
                font-size: 16px;
                font-weight: 300;
                line-height: 30px;
            }
        }

        .hero-pic {
            max-width: 540px;
            margin-right: auto;
            flex: 0 0 auto;

            @include only-phone-xl {
                width: 100%;
                margin: 0 auto;
                order: 1;
            }

            img {
                width: 100%;
                height: auto;

                @include only-phone-xl {
                    max-width: 350px;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
    }
}