@import "../variables";
@import "../media-queries";

.#{$prefix}-project-acceptance-chart-card {
    display: flex;
    align-items: flex-start;
    
    @include only-phone-lg{
        flex-direction: column;
        align-items: center;
    }

    .project-acceptance-chart-card-img {
        width: 130px;
        height: 130px;
        
        /* background */
        &::before {
            content: '';
            position: absolute;
            width: 100px;
            height: 100px;
            border-radius: 16px;
            background: #E0F2FE;
            z-index: -1;
        }

        img {
            width: 100px;
            height: 100px;
            margin-top: 30px;
            margin-right: 30px;
        }
    }
    
    .project-acceptance-chart-card-content {
        margin-right: 30px;
        padding: 24px;
        background: #FFF;
        border-radius: 16px;
        border: 1px solid rgba(229, 229, 229, 0.80);
        
        @include only-phone-lg{
            margin-right: 0;
            margin-top: 20px;
        }

        .project-acceptance-chart-card-title {
            color: #101828;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;

            @include only-phone-lg{
                text-align: center;
            }
        }

        .project-acceptance-chart-card-description {
            color: #667085;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            margin-top: 16px;

            @include only-phone-lg{
                text-align: center;
                font-size: 14px;
                margin-top: 20px;
            }
        }
    }

    &.ltr{
        flex-direction: row-reverse;

        @include only-phone-lg{
            flex-direction: column;
        }

        .project-acceptance-chart-card-img {
            direction: ltr;

            img{
                margin-right: 0;
                margin-left: 30px;
            }
        }
        
        .project-acceptance-chart-card-content {
            margin-right: 0;
            margin-left: 30px;

            @include only-phone-lg{
                margin-left: 0;
            }
        }
    }
}