@import "../variables";

.#{$prefix}-service-card-mobile-wrap {
    height: 100%;
    padding-top: 30px;
    --icon-color: #101828;
    --icon-bg-color: #E0F2FE;
    
    .service-card-mobile {
        border: 1px solid var(--gray-200, #EAECF0);
        border-radius: 14px;
        background: #fff;
        height: 100%;

        .service-card-mobile-icon-wrap {
            width: 110px;
            height: 110px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--icon-bg-color);
            color: var(--icon-color);
            margin: 0 auto;
            margin-top: -30px;
            border-radius: 14px;

            i {
                font-size: 44px;
            }
        }

        .service-card-mobile-content {
            padding: 20px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .service-card-mobile-title {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                text-align: center;
            }

            .service-card-mobile-description {
                margin-top: 16px;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.5;
                color: #667085;
                text-align: center;
            }
        }
    }
}