@import "../../variables";
@import "../../_media-queries";
@import "../../_shared-styles";

.about-section {
    margin-top: 64px;
    padding: 24px 0;

    @include only-phone-xl {
        margin-top: 40px;
    }

    .about-content-wrap {
        display: flex;
        align-items: center;
        
        @include only-phone-xl{
            flex-wrap: wrap;
        }

        .about-title-mobile{
            @extend .main-title;
            width: 100%;
            text-align: center;
            display: none;
            
            @include only-phone-xl{
                display: block;
            }
        }

        .about-pic {
            max-width: 384px;
            margin-right: 40px;
            flex: 0 0 auto;

            @include only-phone-xl{
                width: 100%;
                margin: 0 auto;
                margin-top: 24px;
            }

            @include only-phone{
                padding: 0 25px;
            }

            img{
                width: 100%;
                height: auto;
            }
        }
        
        .about-content {
            max-width: 650px;
            margin-right: 145px;
            
            @include only-phone-xl{
                width: 100%;
                flex: 0 0 auto;
                margin: 0 auto;
            }

            .about-title {
                @extend .main-title;

                @include only-phone-xl{
                    display: none;
                }
            }

            .about-desc {
                margin-top: 32px;
                color: #667085;
                text-align: right;
                font-size: 16px;
                font-weight: 300;
                line-height: 30px;

                @include only-phone-xl{
                    margin-top: 30px;
                    text-align: center;
                }
            }

            .about-btns {
                margin-top: 32px;

                @include only-phone-xl{
                    display: flex;
                    justify-content: center;
                }

                .about-us-btn{
                    @include only-phone{
                        width: 100%;
                    }
                }
            }
        }
    }
}