@import "../../variables";
@import "../../media-queries";

.req-counseling-section {
    padding: 70px 0;

    @include only-phone-xl {
        padding: 40px 0;
    }

    .req-counseling-content {
        .req-counseling-cta-card {
            position: relative;
            padding: 20px 40px;

            @include only-phone-xl {
                height: auto;
                padding: 0;
                max-width: 650px;
                margin: 0 auto;
            }
            
            &::before{
                @include only-desktop-xl{
                    transform: rotate(2deg)
                }
            }

            .cta-card-content-wrap {
                @include only-phone-xl {
                    overflow: hidden;
                    border-radius: 30px;
                }

                .cta-card-img {
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    border-bottom-left-radius: 30px;

                    @include only-phone-xl {
                        position: static;
                        width: 100%;
                        height: auto;
                        border-bottom-left-radius: 0;
                        margin-top: 5px;
                    }
                }

                .cta-card-content {
                    max-width: 470px;
                    
                    @include only-phone-xl {
                        padding: 40px 20px;
                        margin: 0 auto;
                    }

                    .cta-card-title {
                        font-size: 30px;
                        font-weight: 800;
                        color: #fff;

                        @include only-phone-xl {
                            font-size: 24px;
                            text-align: center;
                        }
                    }

                    .cta-card-desc {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 26px;
                        color: #fff;
                    }

                    .cta-card-btns {
                        margin-top: 20px;

                        @include only-phone-xl {
                            margin-top: 18px;
                        }

                        .req-counseling-btn{
                            @include only-phone-xl {
                                display: block;
                                margin: 0 auto;
                            }

                            @include only-phone {
                                width: 100%;
                            }
                        }
                    }
                }
            }

        }
    }
}