@import "../variables";

.#{$prefix}-loader {
    --loader-size: 48px;
    --loader-thickness: 5px;
    --loader-color: #0EA5E9;
    width: var(--loader-size);
    height: var(--loader-size);
    border: var(--loader-thickness) solid;
    border-color: var(--loader-color) transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: loader-rotation 1s linear infinite;
}

@keyframes loader-rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}