@import "../../variables";
@import "../../media-queries";

.contact-us {
    padding: 140px 0;

    @include only-phone-lg {
        padding: 32px 0;
    }

    .contact-us-content {
        display: flex;
        align-items: flex-start;

        @include only-phone-md {
            flex-wrap: wrap;
        }

        .contact-us-inner-content {
            max-width: 400px;
            flex: 0 0 auto;
            margin-left: 32px;

            @include only-phone-lg {
                max-width: 320px;
            }

            @include only-phone-md {
                width: 100%;
                margin: 0 auto;
            }

            .contact-us-title-wrap {
                .contact-us-top-title {
                    color: #101828;
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 20px;

                    @include only-phone-md {
                        text-align: center;
                    }
                }

                .contact-us-title {
                    color: #101828;
                    font-size: 40px;
                    font-weight: 800;
                    line-height: 60px;

                    @include only-phone-lg {
                        font-size: 28px;
                        line-height: 46px
                    }

                    @include only-phone-md {
                        text-align: center;
                    }
                }
            }

            .contact-info {
                border-radius: 14px;
                // background: var(--gray-700, #344054);
                margin-top: 40px;

                ul {
                    display: flex;
                    flex-direction: column;

                    @include only-phone-md {
                        max-width: 250px;
                        flex-direction: column;
                        align-items: flex-start;
                        margin: 0 auto;
                    }

                    li {
                        display: flex;
                        align-items: center;

                        margin-bottom: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .contact-info-icon {
                            width: 48px;
                            height: 48px;
                            margin-left: 20px;
                            border-radius: 12px;
                            background: #E0F2FE;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #0EA5E9;

                            i {
                                font-size: 22px;
                            }
                        }

                        .contact-info-text {
                            direction: ltr;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            color: #475467;
                        }
                    }
                }
            }
        }

        .contact-us-card {
            margin-right: auto;
            max-width: 670px;

            @include only-phone-md {
                max-width: none;
                width: 100%;
                flex: 0 0 auto;
                margin-top: 40px;
                margin-right: 0;
            }
        }
    }
}

.decoration-base {
    z-index: -1;
    pointer-events: none;
}

.contact-us-dec-1 {
    @extend .decoration-base;
    position: relative;

    .dec {
        position: absolute;
        top: 20px;
        left: -60px;

        @include only-phone-xxl {
            left: -50px;
        }

        @include only-phone-md {
            top: 20px;
            left: -45px;
        }

        svg {
            // aspect-ratio: 156 / 142;
            @include only-phone-xxl {
                width: 110px;
                height: 100px;
            }
        }
    }
}

.contact-us-dec-2 {
    @extend .decoration-base;
    position: relative;

    .dec {
        position: absolute;
        top: -170px;
        right: -75px;

        @include only-phone-xxl {
            top: -115px;
            right: -40px;
        }

        @include only-phone-md {
            display: none;
        }

        svg {

            // aspect-ratio: 166 / 167;
            @include only-phone-xxl {
                width: 90px;
                height: 90px;
            }
        }
    }
}