@import "../../variables";
@import "../../_media-queries";
@import "../../_shared-styles";

.portfolio-section {
    margin-top: 64px;
    padding: 24px 0;

    @include only-phone-xl{
        margin-top: 40px;
    }

    .portfolio-header-wrap {
        .portfolio-header{
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .portfolio-header-start{
                @include only-phone-md{
                    width: 100%;
                    flex: 0 0 auto;
                    display: flex;
                    justify-content: center;
                }

                .portfolio-title {
                    @extend .main-title;
                    text-align: right;
                }
            }

            .portfolio-header-end{
                margin-right: auto;

                @include only-phone-md{
                    width: 100%;
                    flex: 0 0 auto;
                    display: flex;
                    justify-content: center;
                    margin-right: 0;
                    margin-top: 30px;
                }
            }
        }
    }

    .portfolio-content {
        margin-top: 42px;
        
        .portfolio-slider-wrapper{
            .slider-footer{
                margin-top: 26px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                @include only-phone-md{
                    margin-top: 30px;
                }

                .slider-footer-start{
                    @include only-phone-md{
                        width: 100%;
                        flex: 0 0 auto;
                        display: flex;
                        justify-content: center;
                    }

                    .slider-btns{
                        display: flex;
                        align-items: center;

                        .slider-btn-next,
                        .slider-btn-prev{
                            --btn-color: var(--primary-color);
                            --btn-color-hover: var(--primary-color);
                        }

                        .slider-dots{
                            margin: 0 12px;
                        }
                        
                    }
                }

                .slider-footer-end{
                    margin-right: auto;

                    @include only-phone-md{
                        margin-right: 0;
                        width: 100%;
                        flex: 0 0 auto;
                        display: flex;
                        justify-content: center;
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}