@import "../variables";

.#{$prefix}-service-card {
    --icon-color: #101828;
    --icon-bg-color: #E0F2FE;
    display: flex;
    border: 1px solid var(--gray-200, #EAECF0);
    border-radius: 14px;
    overflow: hidden;
    background: #fff;
    height: 100%;
    
    .service-card-icon-wrap {
        width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--icon-bg-color);
        color: var(--icon-color);
        transition: all 0.2s ease-in-out;

        i {
            font-size: 44px;
        }
    }

    .service-card-content {
        padding: 30px 25px;
        width: 100%;
        display: flex;
        flex-direction: column;
        transition: all 0.2s ease-in-out;

        .service-card-title {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            transition: all 0.2s ease-in-out;
        }

        .service-card-description {
            margin-top: 24px;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            color: #667085;
            transition: all 0.2s ease-in-out;
        }
    }

    &:hover{
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        .service-card-icon-wrap {
            background: var(--primary-color);
            color: #fff;
        }
        
        .service-card-content {
            background: var(--primary-color-action);

            .service-card-title {
                color: #fff;
            }

            .service-card-description {
                color: #fff;
            }
        }
    }
}