@import "../../variables";

.#{$prefix}-decoration-4 {
    svg{
        overflow: visible;
        animation: rotate 35s linear infinite;
    }

    path:nth-child(1) {
        animation: vibrate 10s linear infinite;
    }

    path:nth-child(2) {
        transform-origin: center;
        animation: vibrate-with-rotation 8s linear infinite;
    }

    path:nth-child(3) {
        animation: vibrate 10s linear infinite;
    }

    path:nth-child(4) {
        animation: vibrate 9s linear infinite;
    }

    path:nth-child(5) {
        animation: vibrate 11s linear infinite;
    }
}