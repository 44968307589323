@import "../../variables";
@import "../../media-queries";

.#{$prefix}-quick-contact {
    border-radius: 14px;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0px 60px 50px -28px rgba(0, 0, 0, 0.06);
    padding: 20px;

    .quick-contact-title {
        color: #667085;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
    }

    .quick-contact-form{
        display: flex;
        align-items: center;
        margin: 0 -8px;
        margin-top: 24px;

        @include only-phone{
            flex-direction: column;
            margin: -10px 0;
            margin-top: 20px;
        }
        
        .form-input{
            flex: 1;
            padding: 0 8px;

            @include only-phone{
                padding: 10px 0;
                flex: 0 1 auto;
                width: 100%;
            }
            

            .rx-input-primary{
                .rx-input {
                    padding-top: 14px;
                    padding-bottom: 14px;
                    line-height: 12px;
                    font-size: 12px;
                }
            }
        }
        
        .form-btn{
            padding: 0 8px;

            @include only-phone{
                padding: 10px 0;
                flex: 0 1 auto;
                width: 100%;
            }

            .submit-btn-desktop{
                @include only-phone{
                    display: none;
                }
            }

            .submit-btn-phone{
                width: 100%;
                display: none;

                @include only-phone{
                    display: block;
                }
            }
        }
    }
}