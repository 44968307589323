@import "../variables";
@import "../media-queries";

.#{$prefix}-header {
    background: #fff;

    .header-content {
        padding-top: 22px;
        padding-bottom: 22px;
        display: flex;
        align-items: center;

        .header-start {
            display: flex;
            align-items: center;

            .header-logo {
                img {
                    width: 116px;
                    height: auto;
                }
            }

            .header-menu {
                margin-right: 44px;

                @include only-phone-lg {
                    display: none;
                }

                ul {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                li {
                    margin-left: 32px;

                    &:last-child {
                        margin-left: 0;
                    }
                }

                .header-link {
                    color: #475467;
                    font-size: 14px;
                    font-weight: 600;
                    position: relative;

                    &::before {
                        content: "";
                        display: block;
                        width: 3px;
                        height: 3px;
                        border-radius: 50%;
                        background: transparent;
                        position: absolute;
                        top: 50%;
                        right: -8px;
                        transform: translateY(-50%);
                        transition: background 0.3s ease;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        bottom: -10px;
                        width: 0;
                        height: 2px;
                        background-color: var(--secondary-color);
                        transition: width 0.3s ease;
                    }

                    &:hover {
                        &::after {
                            width: 100%;
                        }
                    }

                    &.active {
                        color: var(--secondary-color);

                        &::before {
                            background: var(--secondary-color);
                        }
                    }
                }
            }
        }

        .header-end {
            margin-right: auto;

            .req-counseling-btn {
                @include only-phone-lg {
                    display: none;
                }
            }

            .open-sidebar-btn {
                width: 40px;
                height: 40px;
                min-height: auto;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;

                img {
                    width: 24px;
                    height: auto;
                }

                @include only-desktop-lg {
                    display: none;
                }
            }
        }
    }

    .mobile-sidebar-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.7);
        transition: opacity 0.2s ease-in-out;
        opacity: 0;
        z-index: 999;
        pointer-events: none;

        &.active {
            opacity: 1;
            pointer-events: auto;
        }
    }

    .mobile-sidebar {
        position: fixed;
        top: 0;
        right: 0;
        width: 250px;
        height: 100%;
        background-color: #fff;
        transition: transform 0.3s ease-in-out;
        transform: translateX(100%);
        z-index: 1000;
        background: #F8FCFF;

        &.active {
            transform: translateX(0);

            .mobile-sidebar-close-btn {
                left: -25px;
            }
        }

        .mobile-sidebar-close-btn {
            background: #F8FCFF;
            border-radius: 10px 0 0 10px;
            color: #000;
            display: flex;
            width: 25px;
            height: 30px;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 80px;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: left 0.5s ease-in-out;

            i {
                font-size: 14px;
            }

            &::before {
                content: "";
                width: 10px;
                height: 16px;
                position: absolute;
                top: -16px;
                right: 0;
                border-bottom-right-radius: 8px;
                box-shadow: 0px 8px 0 0px #F8FCFF;
                z-index: -1;
            }

            &::after {
                content: "";
                width: 10px;
                height: 16px;
                position: absolute;
                bottom: -16px;
                right: 0;
                border-top-right-radius: 8px;
                box-shadow: 0px -8px 0 0px #F8FCFF;
                z-index: -1;
            }
        }

        .mobile-sidebar-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 5;
            overflow-y: auto;

            .mobile-sidebar-header {
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #fff;

                .mobile-sidebar-logo {
                    margin: 0 auto;

                    img {
                        width: 116px;
                        height: auto;
                    }
                }
            }

            .mobile-sidebar-menu {
                ul {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 0 10px;
                    margin-top: 15px;

                    li {
                        margin-bottom: 5px;
                        width: 100%;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            font-size: 14px;
                            font-weight: 600;
                            display: block;
                            width: 100%;
                            padding: 12px 15px;
                            border-radius: 10px;
                            display: flex;
                            align-items: center;
                            transition: background-color 0.2s ease-in-out;

                            i {
                                margin-left: 10px;
                                font-size: 20px;
                            }

                            &:hover {
                                background-color: #F0F0F0;
                            }

                            &.active {
                                background-color: var(--secondary-color);
                                color: #fff;
                            }

                        }
                    }
                }
            }

            .mobile-sidebar-footer {
                margin-top: 50px;
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                .req-counseling-btn {}
            }
        }
    }
}