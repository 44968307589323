@import "../variables";

.#{$prefix}-cta-card {
    background: var(--primary-color);
    padding: 40px;
    border-radius: 30px;
    position: relative;
    //height: 260px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid var(--primary-color);
        border-radius: 30px; 
        transform: rotate(3.836deg);
        pointer-events: none;
        z-index: -1;
    }
}