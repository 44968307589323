@import "../../variables";
@import "../../_media-queries";
@import "../../_shared-styles";

.project-acceptance-chart {
    margin-top: 64px;
    padding: 24px 0;

    @include only-phone-xl {
        margin-top: 40px;
    }

    .project-acceptance-header-wrap {
        .project-acceptance-header {
            .project-acceptance-title {
                @extend .main-title;
                text-align: center;
            }
        }
    }

    .project-acceptance-content {
        margin-top: 64px;

        @include only-phone-xl {
            margin-top: 40px;
        }

        .pa-chart-wrapper {
            max-width: 728px;
            margin: 0 auto;

            .pa-chart {
                .pa-chart-item {
                    margin-bottom: 60px;
                    position: relative;

                    .pa-chart-arrow {
                        position: absolute;
                        top: 50%;
                        right: -10px;
                        transform: translateX(100%);
                        pointer-events: none;
                        width: 55px;
                        height: 155px;

                        @include only-phone-lg {
                            top: auto;
                            bottom: -16px;
                            right: auto;
                            left: 0;
                            transform: scaleX(-1) translateY(100%);
                        }

                        &.ltr {
                            right: auto;
                            left: -10px;
                            transform: scaleX(-1) translateX(100%);

                            @include only-phone-lg {
                                left: auto;
                                right: 0;
                                transform: translateY(100%);
                            }
                        }
                    }
                }
            }
        }
    }
}