@import "../variables";
@import "../media-queries";

.#{$prefix}-tabs{
  display: flex;
  align-items: center;
  padding-bottom: 28px;
  border-bottom: 1px solid #ccc;
  position: relative;
  width: max-content;
  
  .tab-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0 15px;
    font-size: 16px;
    color: #333;
    outline: none;
    
    &.active{
      color: var(--secondary-color);
    }
  }

  .tabs-slider {
    height: 2px;
    background-color: var(--secondary-color);
    position: absolute;
    bottom: -1px;
    
    &.transition{
        transition: left 0.3s, width 0.3s;
    }
  }
}