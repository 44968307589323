@import "../../variables";
@import "../../_media-queries";
@import "../../_shared-styles";

.hero-section-3 {
    padding: 80px 0;

    @include only-phone-xl {
        padding: 32px 0;
    }

    .hero-content-wrap {
        display: flex;
        align-items: center;

        @include only-phone-xl {
            flex-wrap: wrap;
        }

        .hero-content {
            max-width: 600px;
            margin-left: 30px;

            @include only-phone-xl {
                width: 100%;
                flex: 0 0 auto;
                margin: 0 auto;
                order: 2;
                margin-top: 40px;
            }

            .hero-text {
                color: #667085;
                font-size: 16px;
                font-weight: 300;
                line-height: 30px;
            }
        }

        .hero-pic {
            max-width: 540px;
            margin-right: auto;
            flex: 0 0 auto;
            margin-left: 32px;

            @include only-phone-xl {
                width: 100%;
                margin: 0 auto;
                order: 1;
            }

            img {
                width: 100%;
                height: auto;

                @include only-phone-xl {
                    max-width: 350px;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
    }
}