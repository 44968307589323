@import "../../variables";

.#{$prefix}-decoration-5 {
    svg{
        overflow: visible;
    }
    
    path:nth-child(1) {
        animation: vibrate 9s linear infinite;
    }

    path:nth-child(2) {
        animation: vibrate-with-zoom 25s linear infinite, zoom 1s linear infinite;
    }

    path:nth-child(3) {
        animation: vibrate-with-rotation 10s linear infinite;
    }

    path:nth-child(4) {
        animation: vibrate-with-rotation 14s linear infinite;
    }
}