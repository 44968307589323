@import "../../variables";
@import "../../media-queries";

.title-hero {
    .title-hero-content {
        .hero-img {
            height: 370px;
            flex: 0 0 auto;
            border-radius: 30px;
            background: var(--primary-color);
            position: relative;
            overflow: hidden;

            @include only-phone-xl {
                width: 100%;
                height: 210px;
            }

            .img-1 {
                width: 523px;
                height: 500px;
                position: absolute;
                top: 27px;
                left: 80px;

                @include only-phone-xl {
                    width: 300px;
                    height: 282px;
                    top: 12px;
                    left: 100px;
                }

                @include only-phone-lg {
                    left: 80px;
                }

                @include only-phone-md {
                    display: none;
                }
            }

            .img-2 {
                width: 307px;
                height: 345px;
                position: absolute;
                top: 46px;
                right: 80px;

                @include only-phone-xl {
                    width: 178px;
                    height: 200px;
                    top: 12px;
                    right: 100px;
                }

                @include only-phone-lg {
                    right: 80px;
                }

                @include only-phone {
                    right: 20px;
                }
            }
        }

        .hero-inner-content {
            border-radius: 12px; 
            padding: 27px;
            background: #F2F4F7;
            margin: 0 65px;
            margin-top: -42px;
            position: relative;
            z-index: 1;

            @include only-phone-md {
                margin-left: 35px;
                margin-right: 35px;
            }
            
            @include only-phone {
                margin-left: 20px;
                margin-right: 20px;
            }
        }
    }
}