
@import "../variables";
@import "../media-queries";

.page-404 {
    padding: 32px 0;

    .page-404-content {
        .page-404-img {
            max-width: 500px;
            margin: 0 auto;
        }

        .page-404-inner-content {
            padding: 20px 0;
            text-align: center;

            .page-404-title {
                font-size: 30px;
                font-weight: 700;
            }

            .page-404-desc {
                margin-top: 16px;
                font-size: 18px;
                font-weight: 400;
                color: #667085;
            }
        }

    }
}

.decoration-base{
    z-index: -1;
    pointer-events: none;
}

.page-404-dec-1 {
    @extend .decoration-base;
    position: relative;

    .dec{
        position: absolute;
        top: 50px;
        right: -60px;

        @include only-phone-xl{
            right: -50px;
        }

        @include only-phone-md{
            top: 20px;
            right: -45px;
        }

        svg {
            // aspect-ratio: 166 / 167;
            @include only-phone-xl{
                width: 115px;
                height: 115px;
            }

            @include only-phone-md{
                width: 90px;
                height: 90px;
            }
        }
    }
}

.page-404-dec-2 {
    @extend .decoration-base;
    position: relative;

    .dec{
        position: absolute;
        top: -210px;
        left: -70px;

        @include only-phone-xl{
            top: -115px;
            left: -50px;
        }

        svg {
            // aspect-ratio: 156 / 142;
            @include only-phone-xl{
                width: 110px;
                height: 100px;
            }
        }
    }
}
