@import "../variables";
@import "../media-queries";

.#{$prefix}-footer {
    background: #475467;

    .footer-content {
        padding-top: 32px;
        padding-bottom: 32px;

        .footer-top {
            display: flex;
            align-items: center;

            @include only-phone-md {
                flex-direction: column;

            }

            .footer-top-start {
                display: flex;
                align-items: center;

                @include only-phone-md {
                    flex-direction: column;
                }

                .footer-logo {
                    img {
                        width: 116px;
                        height: auto;
                    }
                }

                .footer-vertical-divider {
                    width: 1px;
                    height: 30px;
                    background: rgba(255, 255, 255, 0.40);
                    margin: 0 22px;

                    @include only-phone-md {
                        display: none;
                    }
                }

                .footer-slogan {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 30px;
                    color: #FFFFFF;

                    @include only-phone-md {
                        margin-top: 16px;
                    }
                }
            }

            .footer-top-end {
                margin-right: auto;

                @include only-phone-md {
                    width: 100%;
                    margin-right: 0;
                    margin-top: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .req-counseling-btn {
                    @include only-phone-md {
                        width: 100%;
                    }
                }
            }
        }

        .footer-contact-info {
            border-radius: 14px;
            padding: 20px;
            background: var(--gray-700, #344054);
            margin-top: 32px;

            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include only-phone-md {
                    max-width: 250px;
                    flex-direction: column;
                    align-items: flex-start;
                    margin: 0 auto;
                }

                li {
                    display: flex;
                    align-items: center;

                    @include only-phone-md {
                        margin-left: 0;
                        margin-bottom: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .contact-info-icon {
                        width: 48px;
                        height: 48px;
                        margin-left: 20px;
                        border-radius: 12px;
                        background: #667085;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;


                        i {
                            font-size: 22px;
                        }
                    }

                    .contact-info-text {
                        direction: ltr;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .footer-bottom {
            margin-top: 32px;
            display: flex;
            align-items: center;

            @include only-phone {
                flex-direction: column;
            }

            .footer-bottom-start {
                .footer-menu {
                    ul {

                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        margin: -8px -16px;

                        li {
                            padding: 8px 16px;

                            a {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                                color: #FFFFFF;
                            }
                        }
                    }

                    .footer-link {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        color: #FFFFFF;
                        position: relative;


                        &::after {
                            content: '';
                            position: absolute;
                            right: 0;
                            bottom: -10px;
                            width: 0;
                            height: 2px;
                            background-color: #fff;
                            transition: width 0.3s ease;
                        }

                        &:hover {
                            &::after {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .footer-bottom-end {
                margin-right: auto;

                @include only-phone {
                    margin-right: 0;
                    margin-top: 30px;
                }

                .footer-social {
                    ul {
                        direction: ltr;
                        display: flex;
                        align-items: center;

                        li {
                            margin-right: 10px;

                            &:last-child {
                                margin-right: 0;
                            }

                            a {
                                width: 48px;
                                height: 48px;
                                border-radius: 12px;
                                background: #344054;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #fff;
                                transition: background-color 0.3s ease-in-out;

                                &:hover {
                                    background: var(--primary-color, #667085)
                                }

                                i {
                                    font-size: 22px;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    .footer-copyright {
        padding: 32px;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        border-top: 1px solid rgba(255, 255, 255, 0.10);
        position: relative;

        @include only-phone-md {
            font-size: 14px;
            padding: 20px;
        }

        .copyright-decoration{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.1;
        }
    }
}