@import "../../variables";
@import "../../media-queries";

.decoration-base{
    z-index: -1;
    pointer-events: none;
}

.about-dec-1 {
    @extend .decoration-base;
    position: relative;

    .dec{
        position: absolute;
        top: 50px;
        left: -60px;

        @include only-phone-xl{
            left: -50px;
        }

        @include only-phone-md{
            top: 100px;
            left: -45px;
        }

        svg {
            // aspect-ratio: 166 / 167;
            @include only-phone-xl{
                width: 115px;
                height: 115px;
            }

            @include only-phone-md{
                width: 100px;
                height: 100px;
            }
        }
    }
}

.about-dec-2 {
    @extend .decoration-base;
    position: relative;

    .dec{
        position: absolute;
        top: -70px;
        right: -70px;

        @include only-phone-xl{
            top: -15px;
            right: -50px;
        }

        svg {
            // aspect-ratio: 156 / 142;
            @include only-phone-xl{
                width: 105px;
                height: 96px;
            }
        }
    }
}


.about-dec-3 {
    @extend .decoration-base;
    position: relative;

    .dec{
        position: absolute;
        top: -75px;
        left: -60px;

        @include only-phone-xl{
            top: -25px;
            left: -40px;
        }

        svg {
            // aspect-ratio: 164 / 205;
            @include only-phone-xl{
                width: 90px;
                height: 113px;
            }
        }
    }
}

.about-dec-4 {
    @extend .decoration-base;
    position: relative;

    .dec{
        position: absolute;
        top: 500px;
        right: -60px;

        @include only-phone-xl{
            top: -55px;
            right: -40px;
        }

        @include only-phone-md{
            top: -30px;
        }

        svg {
            // aspect-ratio: 202 / 210;
            width: 170px;
            height: 177px;

            @include only-phone-xl{
                width: 100px;
                height: 104px;
            }
        }
    }
}

.about-dec-5 {
    @extend .decoration-base;
    position: relative;

    .dec{
        position: absolute;
        top: -500px;
        left: -35px;

        @include only-phone-xl{
            top: -55px;
            left: -30px;
        }

        @include only-phone-md{
            top: -60px;
        }

        svg {
            // aspect-ratio: 155 / 168;
            width: 130px;
            height: 141px;

            @include only-phone-xl{
                width: 90px;
                height: 97px;
            }

            @include only-phone-md{
                width: 80px;
                height: 86px;
            }
        }
    }
}

.about-dec-6 {
    @extend .decoration-base;
    position: relative;

    .dec{
        position: absolute;
        top: -20px;
        right: -70px;

        @include only-phone-xl{
            top: -5px;
            right: -50px;
        }

        svg {
            // aspect-ratio: 156 / 142;
            @include only-phone-xl{
                width: 105px;
                height: 96px;
            }
        }
    }
}